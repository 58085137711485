<template>
    <div>
      <b-navbar toggleable="lg"
                fixed="top"
                type="light"
                class="custom-navbar"
                variant="black">
        <b-navbar-brand  id="navbar" @click="scrollMeTo('lottery')" >
          <div class="custom-logo">
            <img class="logo-img" src="../assets/Logo.png" alt="">
          </div>
        </b-navbar-brand>
        <b-collapse v-model="isNavbarCollapseOpen" id="nav-collapse" is-nav>
          <b-navbar-nav  class="mx-auto">
            <b-nav-item class="nav-itm-custom" @click.prevent="scrollMeTo('building')" >Building</b-nav-item>
            <b-nav-item class="nav-itm-custom" @click.prevent="scrollMeTo('apartments')" >Apartments</b-nav-item>
            <b-nav-item class="nav-itm-custom" @click.prevent="scrollMeTo('amenities')" >Amenities</b-nav-item>
            <b-nav-item class="nav-itm-custom" @click.prevent="scrollMeTo('long-island-city')" >Long Island City</b-nav-item>
            <b-nav-item class="nav-itm-custom" @click.prevent="scrollMeTo('availability')" >Availability</b-nav-item>
          </b-navbar-nav>
        </b-collapse>
        <div class="sign-up d-flex align-items-center justify-content-end">
<!--          <button class="btn-sign-up" v-b-modal.sign-up-modal > SIGN ME UP </button>-->
          <button class="btn-sign-up" @click="openSite()" > FAQs </button>
          <b-navbar-toggle variant="light" class="toggle-button" target="nav-collapse">
            <template #default>
              <div class="menu-icn"></div>
            </template>
          </b-navbar-toggle>
<!--          <div class="help-wrap d-flex align-items-center">-->
<!--            <i class="material-icons" @click="scrollMeTo('faq')">help_outline</i>-->
<!--          </div>-->
        </div>
      </b-navbar>
      <div v-if="isNavbarCollapseOpen" @click="isNavbarCollapseOpen = false" class="navbar-backdrop">
      </div>
    </div>
</template>
<script>

export default {
name: "Navbar",
  data: () => ({
    isNavbarCollapseOpen: false,
  }),
  mounted(){
      let ref = window.location.hash.split('#')[1]
    if(ref){
      this.scrollMeTo(window.location.hash.split('#')[1]);
    }
  },
  methods: {
    openSite(){
      window.open("https://garnet-vein-480.notion.site/5Pointz-FAQs-e5021b8401414e4d87c3c0f4e5338f5f", "_blank");
    },
    scrollMeTo(refName) {
      let element = document.getElementById('_' + refName);
      let navbarElement = document.getElementById('navbar');
      let navbarHeight = navbarElement.offsetHeight + 30;
      let top = element.offsetTop;
      let topCalculate = top - navbarHeight - 5;
      window.location.hash = `#${refName}`;
      if(refName === 'building' && document.body.clientWidth > 1000) {
        topCalculate = top - navbarHeight - 100
      }
      if(refName === 'apartments' && document.body.clientWidth > 1000 ) {
        topCalculate = top - navbarHeight - 150
      }
      if(refName === 'amenities' || refName === 'availability' || refName === 'long_island_city') {
        topCalculate = top - navbarHeight
      }
      window.scroll(0, topCalculate);
    },
  }
}
</script>
<style >
html {
  scroll-behavior: smooth;
}
.navbar-backdrop {
  z-index: 1029;
  background-color: rgba(0, 0, 0, 0);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.material-icons{
  font-size: 32px !important;
  line-height: 48px;
  color: #ffffff;
  vertical-align: top;
}
.help-wrap{
  margin-left: 18px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.toggle-button{
  margin-left: 10px;
}
.logo-img{
  width: 100%;
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0)!important;
  border-color: rgba(0, 0, 0, 0)!important;;
}
.menu-icn{
  background: url("../assets/menu.png");
  background-size: cover;
  background-position: center;
  width: 25px;
  height: 19px;
}
.bg-black{
  background: rgba(7, 7, 7, 0.9)
}
.help{
    background: url("../assets/help_outline.png");
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
}
.btn-sign-up {
    position: relative;
    width: 203px;
    height: 56px;
    border-radius: 4px;
    background: #2692F5;
    border: 1px solid #2692F5;
    color: #FFFFFF;
    font-family: "Poppins";
    font-weight: bold;
    font-size: 19px;
    line-height: 48px;
    letter-spacing: 0;
    margin-right: 10px;
}
.btn-sign-up:hover {
  top: -1px;
  left: -1px;
}
.sign-up{
    width: 368px;
    padding: 5px;
}
.custom-logo{
    margin: 10px 24px;
  cursor: pointer;
}
.custom-navbar{
    min-height: 100px;
    box-shadow: 0 2px 10px -2px #02252a;
    padding: 5px 1rem 0 1rem;
}
.nav-itm-custom {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 48px;
    margin: 0px 15px ;
    letter-spacing: 0.02em;
    cursor: pointer;
    color: #FFFFFF !important;
}
.nav-itm-custom:hover {
    text-decoration: underline;
}
.nav-itm-custom .nav-link{
    color: #FFFFFF !important;
}
@media  (max-width:1441px)  {
  .custom-logo {
    width: 213px;
    height: 32px;
    margin: 10px 4px;
  }
  .nav-itm-custom{
    font-size: 13px;
    margin: 0px 7px ;
  }
  .sign-up{
    width: 213px;
    padding: 5px;
  }
  .btn-sign-up{
    width: 130px;
    height: 48px;
    font-size: 15px;
  }
}
@media  (max-width:1025px)  {
  .custom-navbar{
    min-height: 70px;
  }
  .custom-logo {
    width: 213px;
    height: 32px;
    margin: 10px 4px;
  }
  .nav-itm-custom{
    font-size: 13px;
    margin: 0px 7px ;
  }
  .sign-up{
    width: auto;
    padding: 5px;
  }
  .btn-sign-up{
    width: 130px;
    height: 48px;
    font-size: 15px;
  }
  .material-icons{
    font-size: 24px !important;
    line-height: 48px;
    color: #ffffff;
  }
}
@media  (max-width:767px) {
  .custom-navbar{
    min-height: 50px;
    padding: 5px!important;
  }
  .toggle-button{
    margin-left: 0px;
  }
  .menu-icn {
    width: 17px;
    height: 11px;
  }
  .help-wrap{
    width: 20px;
    height: 20px;
    margin-left: 8px;
  }
  .custom-logo {
    width: 116px;
    height: 28px;
    margin: 0 4px;
  }
  .nav-itm-custom{
    font-size: 13px;
    margin: 0px 7px ;
  }
  .sign-up{
    width: auto;
    padding: 5px;
  }
  .btn-sign-up{
    width: 92px;
    height: 32px;
    font-size: 12px;
    line-height: 32px;
  }
  .material-icons{
    font-size: 24px !important;
    line-height: 48px;
    color: #ffffff;
  }
}
@media  (max-width:769px) and (min-width:321px)  {
  .sign-up{
    position: absolute;
    top: 11px;
    right: 10px;
  }
}
@media  (min-width:319px) and (max-width:450px)  {
  .sign-up{
    position: absolute;
    top: 4px;
    right: 9px;
  }
}

@media  (max-width:500px)  {
  .material-icons{
    font-size: 20px !important;
    line-height: 48px;
    color: #ffffff;
  }
  .navbar-brand {
    margin-right: 0 !important;
  }
}
</style>
