<template>
  <div class="carousel-wrapper">
    <b-carousel
        id="carousel"
        v-model="slide"
        :interval="4000"
        controls
        background="#ababab"
        style="text-shadow: 1px 1px 2px #333;">
      <b-carousel-slide v-for="(image, index) in images" :key="index" :img-src="`./carousel/${image}`"></b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>
export default {
  name: "Carousel",
  data: () => ({
    slide: 0,
    images: [
      '5_Pointz_Living_Room_2_1.png',
      '5_Pointz_Living_Room_2_2.png',
      '5_Pointz_Living_Room_2 3.png',
      '5_Pointz_Living_Room_2 4.png',
      '5_Pointz_Living_Room_2 5.png',
      '5_Pointz_Living_Room_2 6.png',
      '5_Pointz_Living_Room_2 8.png',
      '5_Pointz_Living_Room_2 9.png',
      '5_Pointz_Living_Room_2 10.png',
      '5_Pointz_Living_Room_2 11.png',
      '5_Pointz_Living_Room_2 12.png',
      '5_Pointz_Living_Room_2 13.png',
      '5_Pointz_Living_Room_2 14.png',
      '5_Pointz_Living_Room_2 15.png',
      '5_Pointz_Living_Room_2 16.png',
      '5_Pointz_Living_Room_2 17.png',
      '5_Pointz_Living_Room_2 18.png',
      '5_Pointz_Living_Room_2 19.png',
      '5_Pointz_Living_Room_2 20.png',
      '5_Pointz_Living_Room_2 21.png',
      '5_Pointz_Living_Room_2 22.png',
      '5_Pointz_Living_Room_2 26.png',
      '5_Pointz_Living_Room_2 27.png',
      '5_Pointz_Living_Room_2 28.png',
      '5_Pointz_Living_Room_2 29.png',
      '5_Pointz_Living_Room_2 30.png',
      '5_Pointz_Living_Room_2 31.png',
      '5_Pointz_Living_Room_2 32.png',
      '5_Pointz_Living_Room_2 33.png',
      '5_Pointz_Living_Room_2 34.png',
      '5_Pointz_Living_Room_2 37.png',
    ]
  })
}
</script>

<style>
.carousel-wrapper {
  position: absolute;
  z-index: 10;
  top: -350px;
  width: 1420px;
  height: 700px;
  left: calc(50% - 710px);
}

@media (min-width: 768px) {
  .carousel-control-prev,
  .carousel-control-next {
    position: absolute !important;
    width: 70px !important;
    height: 70px !important;
    right: 0px !important;
    bottom: 0px !important;
    top: auto !important;
    left: auto !important;
    opacity: 1 !important;
    background: #000000;
  }

  .carousel-control-prev {
    right: 72px !important;
  }
}
@media  (max-width:1520px)  {
  .carousel-wrapper {
    top: -350px;
    left: calc(50% - 650px);
    width: 1300px;
    height: 700px;
  }
  .carousel-item,
  .carousel-inner,
  .carousel,
  .carousel-item .img-fluid{
    height: 700px;
  }
}
@media  (max-width:1439px) and (min-width:1024px)  {
  .carousel-wrapper {
    top: -250px;
    left: calc(50% - 480px);
    width: 960px;
    height: 500px;
  }
  .carousel-item,
  .carousel-inner,
  .carousel,
  .carousel-item .img-fluid{
    height: 500px;
  }
}

@media  (max-width:1023px) and (min-width:760px)  {
  .carousel-wrapper {
    position: static;
    width: 100%;
    height: 500px;
  }
  .carousel-control-prev {
    right: auto !important;
    left: 0 !important;
  }
  .carousel-item,
  .carousel-inner,
  .carousel,
  .carousel-item .img-fluid{
    height: 500px;
  }
}

@media  (max-width:760px) and (min-width:320px)  {
  .carousel-wrapper {
    position: static;
    width: 100%;
    height: auto;
  }
  .carousel-item,
  .carousel-inner,
  .carousel,
  .carousel-item .img-fluid{
    height: auto;
  }
}
</style>