<template>
 <div>
   <div class="container-width bg-color-white">
     <div class="container-fluid wrapper-building " ref="building" >
       <div class="row" id="_building">
         <div class="col-lg-3 col-md-12 p-0">
           <p class="title" >Building<span class="text-blue">.</span></p>
         </div>
         <div class="col-lg-7 offset-lg-2 col-md-12 p-0 text-left">
           <h2 class="header" >Create your life in the building of your dreams</h2>
           <p class="content">5 Pointz stands out in the heart of a Long Island City neighborhood buzzing with culture.
             The building boasts near-countless amenities-- from a refreshing splash in the indoor pool,
             to the state-of-the-art fitness center,
             to scenic views at the Sky Lounge.
             Experience big city features without the downside, <span class="content-bold">priced significantly below market-rate rent.</span> </p>
           <p class="content">All units are <span class="text-stabilized">rent stabilized</span>, which guarantees the right of lease renewal and rent increases per DHCR guidelines.</p>
         </div>
       </div>
     </div>
   </div>
 </div>
</template>

<script>
export default {
name: "Building"
}
</script>

<style scoped>
.wrapper-building {
  padding-top: 315px;
  padding-bottom: 562px;
}
.text-stabilized {
  font-family: "Poppins-Bold";
  text-decoration: underline;
}
.header{
  font-family: "Poppins-ExtraBold";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 36px;
  color: #111111;
  margin-bottom: 20px;
}
.content{
  position: static;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 36px;
  color: #111111;
  margin-bottom: 20px;
}
.content-bold{
  font-weight: 800;
}
.title{
  font-family: "Poppins-Bold";
  font-size: 80px;
  line-height: 90px;
  color: #000000;
}
.text-blue{
  color: #2692F5;
}
@media  (max-width:1520px)  {
  .title {
    font-size: 70px;
  }
}
@media  (max-width:1439px) and (min-width:1024px)  {
  .wrapper-building {
    padding-top: 191px;
    padding-bottom: 330px;
  }
  .title{
    font-size: 55px;
    line-height: 90px;
  }
  .header{
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 16px;
  }
  .content{
    font-size: 14px;
    line-height: 26px;
    margin-bottom: 16px;
  }
}
@media  (max-width:1023px) and (min-width:766px)  {
  .wrapper-building {
    padding-top: 147px;
    padding-bottom: 60px;
  }
  .title{
    font-size: 45px;
    line-height: 55px;
    margin-bottom: 30px;
  }
  .header{
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 16px;
  }
  .content{
    font-size: 14px;
    line-height: 26px;
    margin-bottom: 16px;
  }
}
@media  (max-width:767px) and (min-width:320px)  {
  .wrapper-building {
    padding-top: 147px;
    padding-bottom: 60px;
  }
  .title{
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 20px;
  }
  .header{
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
  }
  .content{
    font-size: 12px;
    line-height: 22px;
    margin-bottom: 16px;
  }
}
@media  (max-width:500px) and (min-width:320px)  {
  .wrapper-building {
    padding-top: 40px;
    padding-bottom: 20px;
  }

}

</style>
