<template>
  <div class="preload">
    <div class="preload-img"> </div>
  </div>
</template>

<script>
export default {
name: "Preloader"
}
</script>

<style scoped>
.preload-img{
  background: url(../assets/preload.gif) -128px -111px;
  background-size: 365px;
  width: 125px;
  height: 125px;
  background-repeat: no-repeat;
}
.preload{
  position: absolute;
  top: calc(50% );
  left: calc(50% - 59px);
  z-index: 99999;
}
</style>