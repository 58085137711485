<template>
  <div class="position-relative hero-page-wrapper">
    <div class="5 Pointz LIC - Middle Income Housing lottery" id="_lottery">
      <p class="main-text text-center">HOUSING <span>LOTTERY</span> </p>
      <div class="description  text-center d-flex flex-column justify-content-center">
        <div>
          <p class="mb-2">90,000 SQFT AMENITY PACKAGE  <br /> <span>INCLUDING</span> <RunningLine class="d-inline-block running-line align-top" style="height: 50px"></RunningLine></p>
        </div>
      </div>
      <p class="main-text closed text-center">Applications Closed as of June 8th 2021</p>
    </div>
    <div class="information-block d-flex justify-content-around">
      <div>
        <p class="information-block_contact">Contact Information</p>
        <p class="information-block_phone">Phone number: <a class="information-block_phone call-button" href="tel:718-879-3294">718-879-3294</a></p>
        <p class="information-block_email">Email:  <a  class="information-block_email email-button" href="mailto:info@5pointz-lottery.com">info@5pointz-lottery.com</a>  </p>
      </div>

<!--      <div>-->
<!--        <p class="information-block_looking">Looking for Market-Rate Units?</p>-->
<!--        <div class="link-align">-->
<!--          <a  href="http://5pointzlic.com/#/" target="_blank" class="information-block_link">Click Here</a>-->
<!--        </div>-->
<!--      </div>-->
      <div>
<!--        <button class="information-block_lic-for-less" v-b-modal.sign-up-modal >Sign Up Here</button>-->
        <button class="information-block_lic-for-less" @click="openSite()" > FAQs </button>
      </div>
    </div>
  </div>
</template>

<script>
import RunningLine from '@/components/RunningLine'
export default {
    name: "HeroPage",
    components:{
        RunningLine,
    },
    methods: {
      openSite(){
        window.open("https://garnet-vein-480.notion.site/5Pointz-FAQs-e5021b8401414e4d87c3c0f4e5338f5f", "_blank");
      },
      scrollMeTo(refName) {
        let element = document.getElementById(refName);
        let top = element.offsetTop;
        window.scrollTo(0, top);
      }
    }
}
</script>

<style scoped>
.running-line {
  text-align: left;
}
.hero-page-wrapper {
  max-width: 1920px;
  margin: 0 auto;
}
.link-align{
  text-align: center;
}
.lottery{
  background-size: cover;
  background: url('../assets/hero_2_desc.png');
  background-position: top;
  background-repeat: no-repeat;
  width: 100%;
  height: 800px;
  padding-top: 322px;
  position: relative;
}
.description{
  font-style: normal;
  font-weight: 800;
  font-family: "Integral CF ExtraBold";
  font-size: 36px;
  text-align: center;
  color: #FFFFFF;
  text-shadow: 0px 3.11186px 9.33559px rgba(0, 0, 0, 0.4);
  line-height: 36px;
}
.main-text{
  font-family: "Integral CF ExtraBold";
  font-size: 58px;
  line-height: 72px;
  text-align: center;
  color: #FFFFFF;
  text-shadow: 0px 3.11186px 9.33559px rgba(0, 0, 0, 0.4);
  margin-bottom: 11px;
}
.main-text span{
  display: inline-block;
  border-bottom: 2px solid #fff;
}
.closed{
  font-size: 20px;
}
.information-block {
  position: absolute;
  max-width: 1420px;
  width: 73.9583333333%; /* 1420*100/1920 */
  height: 230px;
  background: #2692F5;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin: 0 auto;
  bottom: -115px;
  z-index: 5;
  left: 13%;
  padding: 67px 3.0729166667%;  /* 59*100/1920 */
}
.information-block_contact {
  font-family: "Poppins-Bold";
  font-size: 20px;
  line-height: 32px;
  color: #FFFFFF;
}
.information-block_phone,
.information-block_email {
  font-family: Poppins;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF!important;
  margin-bottom: 5px;
  text-decoration: none;
}
.information-block_phone a,
.information-block_email a{
  font-family: "Poppins-Bold";
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
}
.information-block_looking {
  font-family: "Poppins-Bold";
  font-size: 20px;
  line-height: 32px;
  color: #FFFFFF;
  margin-bottom: 0px;

}
.information-block_link {
  font-family: "Poppins-Black";
  font-size: 25px;
  line-height: 60px;
  color: #000000;
  text-decoration: underline;
}
.information-block_span {
  font-family: "Poppins-Black";
  font-size: 25px;
  line-height: 60px;
  color: #000000;
}
.information-block_link:hover {
  text-decoration: none;
}
.information-block_lic-for-less {
  width: 360px;
  height: 92px;
  font-family: "Poppins-SemiBold";
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  line-height: 60px;
  color: #fff;
  background: #000000;
  border-radius: 5px;
  border: 1px solid #000000;
  margin-bottom: 4px;
}
.information-block_lic-for-less:hover {
  color:#000000;
  background: #ffffff;
  border: 1px solid #ffffff;
  transition: .35s;
}
.break {
  flex-basis: 100%;
  height: 0;
}

@media screen and (min-width: 1440px) and (max-width: 1919px) {
  .information-block {
    max-width: 1310px;
    width: 100%;
    left: calc(50% - 650px);
    padding: 67px 3.125%;  /* 60*100/1920 */
  }

  .information-block_lic-for-less {
    width: 264px;
    height: 92px;
  }
}

@media screen and (max-width: 1100px) {
  .information-block {
    padding: 67px 1.1666666667%;
  }

  .information-block_lic-for-less {
    width: 264px;
    height: 92px;
  }
  .main-text{
    font-size: 55px;
  }
  .closed{
    font-size: 20px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1439px) {
  .lottery {
    height: 650px;
    padding-top: 220px;
  }
  .main-text{
    font-size: 50px;
    line-height: 60px;
  }
  .closed{
    font-size: 20px;
  }
  .description{
    font-size: 30px;
    line-height: 36px;
  }
  .description p{
    margin-bottom: 0 !important;
  }
  .information-block {
    width: 960px;
    height: 170px;
    bottom: -85px;
    left: calc(50% - 480px);
    padding: 31px 2.5694444444%;
  }

  .information-block_lic-for-less {
    width: 264px;
    height: 92px;
    margin-top: 10px;

  }
  .information-block_contact {
    font-size: 16px;
    margin: 0 0 10px;
  }
  .information-block_phone,
  .information-block_email {
    font-size: 13px;
  }
  .information-block_phone span,
  .information-block_span,
  .information-block_email span{
    font-size: 13px;
  }

  .information-block_looking {
    font-size: 16px;
    margin-bottom: 0px;
  }
  .information-block_span,
  .information-block_link {
    font-size: 22px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .lottery {
    height: 550px;
    padding-top: 210px;
  }
  .main-text{
    font-size: 35px;
    line-height: 42px;
  }
  .closed{
    font-size: 20px;
  }
  .description{
    font-size: 22px;
    line-height: 26px;
  }
  .description p{
    margin-bottom: 0 !important;
  }
  .information-block {
    width: 643px;
    height: 250px;
    left: calc(50% - 321px);
    padding: 31px 30px;
    display: block !important;
  }
  .information-block div {
    display: inline-block;
    width: 45%;
    margin-right: 5%;
  }
  .information-block div:nth-of-type(2) {
    margin-right: 0px !important;
  }
  .information-block div:last-of-type {
    display: block;
    width: 100%;
  }
  .information-block_lic-for-less {
    margin-top: 20px;
    width: 583px;
    height: 60px;
  }
  .information-block_contact {
    font-size: 16px;
    margin: 0 0 10px;
  }
  .information-block_phone,
  .information-block_email {
    font-size: 13px;
  }
  .information-block_phone span,
  .information-block_email span{
    font-size: 13px;
  }

  .information-block_looking {
    font-size: 16px;
    margin-bottom: 2px;
  }
  .information-block_span,
  .information-block_link {
    font-size: 22px;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .lottery {
    height: 460px;
    padding-top: 141px;
  }
  .link-align{
    text-align: left;
  }
  .main-text{
    font-size: 31px;
    line-height: 37.2px;
    max-width: 234px;
    margin: 0 auto 20px;
  }
  .description{
    font-size: 22px;
    line-height: 26px;
  }
  .description br{
    display: none;
  }
  .description p{
    width: 292px;
    margin: 0 auto;
  }
  .information-block {
    position: static;
    width: 100%;
    height: auto;
    padding: 30px 16px;
    display: block !important;
  }
  .information-block div {
    display: block;
    width: 100%;
    margin-right: 0;
  }
  .information-block div:nth-of-type(1) {
    margin-bottom: 25px !important;
  }
  .information-block div:last-of-type {
    display: block;
    width: 100%;
    margin-top: 5px;
  }
  .information-block_lic-for-less {
    width: 100%;
    height: 60px;
  }
  .information-block_contact {
    font-size: 16px;
    margin: 0 0 5px;
  }
  .information-block_phone,
  .information-block_email {
    font-size: 13px;
  }
  .information-block_phone span,
  .information-block_email span{
    font-size: 13px;
  }

  .information-block_looking {
    font-size: 16px;
    margin-bottom: 0px;
  }
  .information-block_span,
  .information-block_link {
    font-size: 22px;
    line-height : 40px;
  }
  .running-line {
    text-align: center;
  }
}

</style>
