<template>
  <div id="app" >
    <Navbar></Navbar>
    <HeroPage class="bg-color-white"></HeroPage>
    <Building class="bg-color-white"></Building>
    <Apartments class="bg-color-white"></Apartments>
    <LIC class="bg-color-white"></LIC>
    <Ameneties class="bg-color-white"></Ameneties>
    <AffordableRents class="bg-color-white"></AffordableRents>
<!--    <AnyQuestions></AnyQuestions>-->
<!--    <AccordeonFaq></AccordeonFaq>-->
    <FooterWrap></FooterWrap>
    <SignUpModal></SignUpModal>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import LIC from '@/views/LIC.vue'
import HeroPage from '@/views/HeroPage.vue'
import Building from '@/views/Building.vue'
import Apartments from '@/views/Apartments.vue'
import Ameneties from '@/views/Ameneties.vue'
import AffordableRents from '@/views/AffordableRents.vue'
// import AnyQuestions from '@/views/AnyQuestions.vue'
// import AccordeonFaq from '@/components/AccordeonFaq.vue'
import FooterWrap from '@/views/FooterWrap.vue'
import SignUpModal from '@/views/SignUpModal.vue'


export default {
  name: 'App',
  components: {
    Navbar,
    HeroPage,
    Building,
    Apartments,
    LIC,
    Ameneties,
    AffordableRents,
    // AnyQuestions,
    // AccordeonFaq,
    FooterWrap,
    SignUpModal,
  }
}
</script>

<style lang="scss">
@import "https://fonts.googleapis.com/icon?family=Material+Icons";

.container-width{
  width: 100%;
  margin-left: auto !important;
  margin-right: auto !important;
}
.bg-color-white {
  background-color: rgb(255, 255, 255);
}
@media  (min-width:1520px)  {
  .container-width {
    width: 1420px;
  }
}
@media  (max-width:1520px) and (min-width:1440px)  {
  .container-width {
    width: 1301px;
  }
}
@media  (max-width:1439px) and (min-width:1024px)  {
  .container-width {
    width: 960px;
  }
}
@media  (max-width:1023px) and (min-width:760px)  {
  .container-width {
    width: 100%;
    padding: 0 66px;
  }
}
@media  (max-width:760px) and (min-width:320px)  {
  .container-width {
    width: 100%;
    padding: 0 16px;
  }
}


@font-face {
  font-family: "Integral CF ExtraBold";
  src: local("Integral CF"),   url('./fonts/IntegralCF-ExtraBold.woff2')  format("woff2");
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: "Open Sans";
  src:   url('./fonts/OpenSans-Regular.ttf')   format('truetype');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "Open Sans-SemiBold";
  src:   url('./fonts/OpenSans-SemiBold.ttf')   format('truetype');
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Open Sans-Bold";
  src:   url('./fonts/OpenSans-Bold.ttf')   format('truetype');
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Open Sans-Light";
  src:   url('./fonts/OpenSans-Light.ttf')   format('truetype');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Open Sans-ExtraBold";
  src:   url('./fonts/OpenSans-ExtraBold.ttf')   format('truetype');
  font-style: normal;
  font-weight: 800;
}
@font-face {
  font-family: "Poppins";
  src:   url('./fonts/Poppins-Regular.ttf')   format('truetype');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "Poppins-SemiBold";
  src:   url('./fonts/Poppins-SemiBold.ttf')   format('truetype');
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Poppins-Bold";
  src:   url('./fonts/Poppins-Bold.ttf')   format('truetype');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Poppins-ExtraBold";
  src:   url('./fonts/Poppins-Bold.ttf')   format('truetype');
  font-style: normal;
  font-weight: 800;
}
@font-face {
  font-family: "Poppins-Black";
  src:   url('./fonts/Poppins-Black.ttf')   format('truetype');
  font-style: normal;
  font-weight: 900;

}
$grid-breakpoints: (
  xs: 0,
  sm: 376px,
  md: 769px,
  lg: 1024px,
  xl: 1440px,
  xxl: 1920px
);

$container-max-widths: (
sm: 296px,
md: 732px,
lg: 976px,
xl: 1380px,
xxl: 1500px
);

// Import BT sources
@import "../node_modules/bootstrap/scss/bootstrap";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
