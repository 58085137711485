<template>
<div class="amenity-space-wrapper position-relative" id="_amenities">
  <Carousel2></Carousel2>
  <div class="container-width">
    <div class="contaner-fluid">
      <div class="row">
        <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12">
          <p class="title">Amenities<span class="text-blue">.</span></p>
        </div>
        <div class="col-xl-7 col-lg-7 col-md-12 col-sm-12 text-left">
          <h2 class="header">90,000 square feet of amenity space.</h2>
          <p class="content">Don’t just live here, play here. Get an endorphin fix at our stunning all-season swimming
            pool and indoor basketball court, or break a sweat at the 8,000 square foot fitness center. Then, relax by
            grilling with your friends on our outdoor terrace.</p>
          <p class="content">Chill out with friends or make new ones with our incredible all-season Sky Lounge featuring
            indoor and outdoor space, gorgeous views, and well-appointed furnishings. Whether you’re working or
            relaxing, you’ll find a place among the shared spaces at 5 Pointz. </p>
          <h2 class="header">Our community amenities are unparalleled. Period. Amenities managed by an independent 3rd party. Additional fees apply.</h2>
          <div class="">
            <ul class="amenity-space-list row">
              <li class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6"><i class="material-icons">done</i> Sky lounge</li>
              <li class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6"><i class="material-icons">done</i> Attended lobby</li>
              <li class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6"><i class="material-icons">done</i> Basketball court</li>
              <li class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6"><i class="material-icons">done</i> Co-working lounge</li>
              <li class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6"><i class="material-icons">done</i> Courtyard</li>
              <li class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6"><i class="material-icons">done</i> Garden terrace</li>
              <li class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6"><i class="material-icons">done</i> Fitness center</li>
              <li class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6"><i class="material-icons">done</i> Outdoor bbq</li>
              <li class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6"><i class="material-icons">done</i> Spin room</li>
              <li class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6"><i class="material-icons">done</i> Boxing</li>
              <li class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6"><i class="material-icons">done</i> Shuffleboard</li>
              <li class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6"><i class="material-icons">done</i> Poker lounge</li>
              <li class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6"><i class="material-icons">done</i> Business center</li>
              <li class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6"><i class="material-icons">done</i> Golf simulator</li>
              <li class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6"><i class="material-icons">done</i> Indoor pool</li>
              <li class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6"><i class="material-icons">done</i> Sun deck</li>
              <li class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6"><i class="material-icons">done</i> Library</li>
              <li class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6"><i class="material-icons">done</i> Parking</li>
              <li class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6"><i class="material-icons">done</i> Sauna</li>
              <li class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6"><i class="material-icons">done</i> Doorman</li>
              <li class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6"><i class="material-icons">done</i> Resident lounge</li>
              <li class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6"><i class="material-icons">done</i> Gaming room</li>
              <li class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6"><i class="material-icons">done</i> Party room</li>
              <li class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6"><i class="material-icons">done</i> Pet grooming room</li>
            </ul>
          </div>
          * Amenities fees apply
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Carousel2 from '@/components/Carousel2';
export default {
  name: "Appartments",
  components: {
    Carousel2
  }
}
</script>

<style scoped>
.amenity-space-wrapper {
  padding: 670px 0 0px;
}
.amenity-space-list {
  font-family: "Poppins";
  font-size: 20px;
  line-height: 36px;
  color: #111111;
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}
.amenity-space-list li{
  margin-bottom: 16px;
  padding-left: 40px;
  position: relative;
}
.material-icons {
  font-size: 32px;
  vertical-align: top;
  position: absolute;
  color: #2692F5;
  top: 0;
  left: 0;
  line-height: 36px;
}

.title {
  font-family: "Poppins-Bold";
  font-size: 80px;
  line-height: 90px;
  color: #000000;
}
.title .text-blue{
  color:#2692F5;
}
.header {
  font-family: "Poppins-ExtraBold";
  font-size: 24px;
  line-height: 36px;
  color: #111111;
  margin: 0 0 20px;
}
.content {
  font-family: "Poppins";
  font-size: 20px;
  line-height: 36px;
  color: #111111;
  margin-bottom: 20px;
}
.content .content-bold{
  font-family: "Poppins-Bold";
}
@media  (max-width:1520px)  {
  .title {
    font-size: 70px;
  }
}
@media  (max-width:1439px) and (min-width:1024px)  {
  .amenity-space-wrapper {
    padding: 320px 0 0px;
  }
  .amenity-space-list {
    font-size: 14px;
    line-height: 36px;
  }
  .apartments-wrapper {
    padding-top: 330px;
    padding-bottom: 245px;
  }
  .title {
    font-size: 55px;
  }
  .header {
    font-size: 18px;
  }
  .content {
    font-size: 14px;
  }
}

@media  (max-width:1023px) and (min-width:768px)  {
  .amenity-space-list {
    font-size: 14px;
    line-height: 36px;
  }
  .amenity-space-list li{
    margin-bottom: 10px;
    padding-left: 30px;
  }
  .amenity-space-wrapper {
    padding: 120px 0 0px;
  }
  .apartments-wrapper {
    background: #fff;
    padding-top: 0px;
    padding-bottom: 60px;
  }
  .title {
    margin-top: 60px;
    font-size: 45px;
    line-height: 55px;
  }
  .header {
    font-size: 18px;
    line-height: 27px;
  }
  .content {
    font-size: 14px;
    line-height: 26px;
  }
}

@media  (max-width:767px) and (min-width:320px)  {
  .amenity-space-list {
    font-size: 14px;
    line-height: 36px;
  }
  .amenity-space-list li{
    margin-bottom: 0px;
    padding-left: 40px;
  }
  .amenity-space-list {
    font-size: 14px;
    line-height: 36px;
  }
  .amenity-space-list li{
    margin-bottom: 0px;
    padding-left: 40px;
  }
  .amenity-space-wrapper {
    padding: 40px 0 0px;
  }
  .apartments-wrapper {
    padding-top: 0px;
    background: #fff;
    padding-bottom: 20px;

  }
  .title {
    margin-top: 30px;
    font-size: 32px;
    line-height: 38px;
  }
  .header {
    font-size: 16px;

    line-height: 24px;
  }
  .content {
    font-size: 12px;
    line-height: 22px;
  }
}
</style>
