<template>
    <div>
        <hooper :progress="true"
                :vertical="true"
                style="height: 84px"
                :itemsToShow="1"
                :autoPlay="true"
                :infiniteScroll="true"
                :playSpeed="2000">
            <slide v-for="(item,index) in text"
                   class="slide-custom"
                   :key="index">
                {{item}}
            </slide>
        </hooper>
    </div>
</template>

<script>
    import { Hooper, Slide } from 'hooper';
     import 'hooper/dist/hooper.css';
    export default {
        name: "RunningLine",
        components: {
            Hooper,
            Slide
        },
        data : () =>({
            text:[
                'ATTENDED LOBBY',
                'OUTDOOR TERRACES',
                'SKY CLUB',
                'CO-WORKING LOUNGE',
                'FITNESS CENTER',
                'INDOOR POOL',
                'YOGA /BARRE STUDIO',
                'SAUNA',
                'BASKETBALL COURT',
                'BOXING CLASSES',
                'SPIN ROOM',
                'ART GALLERIES',
                'GAME ROOM',
                'BOCCE COURTS',
                'POKER LOUNGE',
                'INDOOR COURTYARD',
                'GOLF SIMULATORS',
                'PET CARE CENTER',
                'PARKING',
                'STORAGE',
                'BICYCLE ROOM',
            ]
        }),
        methods:{
            // makeIntervalSlice: function(){
            //     this.intervalid1 = setInterval(() => {
            //         this.changeWord()
            //     }, 3000);
            // },
            // changeWord(){
            //     this.text.push(this.text.shift())
            // },
        },
        created(){
            // this.makeIntervalSlice();

        },
        beforeDestroy() {
            this.clearInterval(this.intervalid1)
        }
    }
</script>

<style scoped>
    .slide-custom{
        width:100%;
      color: #52acff;
    }
.window{
    height: 40px;
    width: 100%;
    overflow: hidden;
}
.word{
    position: relative;
    top: 0px;
}


</style>
