<template>
<footer >
  <div class="footer-wrapper d-flex justify-content-between">
    <a href="#" class="logo"><img src="../assets/Logo.png" alt=""></a>
    <p class="mb-0 rights"> © Copyright 2021 MGNY Consulting Corp. All Rights Reserved </p>
    <span class="rights">Photos by Adam Kane Macchia  </span>
  </div>
</footer>
</template>

<script>
export default {
name: "FooterWrap"
}
</script>

<style scoped>
footer {
  background: #000000;
  padding: 26px 0;
}
.footer-wrapper {
  width: 1517px;
  margin: 0 auto;
}
.logo {
  width: 144px;
  display: block;
}
.logo img{
  width: 100%;
}
.rights {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #FFFFFF;
}

@media (max-width: 1550px) {
  footer {
    padding: 26px 75px 26px 16.1935483871%;
  }
  .footer-wrapper {
    width: 100%;
  }
}
@media (max-width: 1024px) {
  footer {
    padding: 26px 32px;
  }

}
@media (max-width: 769px) {
  footer {
    padding: 13px 32px;
  }
  .rights {
    font-size: 12px;
    line-height: 24px;
  }

}
@media (max-width: 565px) {
  .footer-wrapper {
    display: block !important;
    text-align: center;
  }
  .rights {
    text-align: center;
  }
  .logo {
    margin: 0 auto;
  }

}
</style>