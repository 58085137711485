<template>
    <div>
        <b-card no-body class="price-body"  v-for="(item, index ) in price_list" :key="index">
            <b-card-header header-tag="div" header-class="card-header-custom" class="p-1" role="tab">
                <div class="p-1"  v-b-toggle="'accordeon-' + index">
                    <table class="table-borderless w-100 t-bg">
                        <tr class="price-head-table">
                            <td class="">
                                <div class="d-flex align-items-center">
                                    <div id="dot-close">
                                      <i class="material-icons">remove_circle_outline</i>
                                    </div>
                                    <div  id="dot-open">
                                      <i class="material-icons">add_circle_outline</i>
                                    </div>
                                    <div class="parent-text d-inline-block align-top">
                                        <p class="question mb-0">{{item.name}}</p>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </b-card-header>
            <b-collapse :id="'accordeon-' + index"
                        accordion="my-accordion"
                        role="tabpanel">
                <b-card-body class="collapse-card-body">
                    <table class="table-borderless w-100 t-bg">
                        <tr class="t-head">
                            <th>Household Size</th>
                            <th>Min. Income</th>
                            <th>Max. Income</th>
                        </tr>
                        <tr v-for="(data, index) in item.table" :key="index" class="t-body" >
                            <td v-if="data.members === 1" class="w-40 w-sm-33">{{data.members}} person</td>
                            <td v-else class="w-40 w-sm-33">{{data.members}} people</td>
                            <td class="w-40 w-sm-33">{{data.min}}</td>
                            <td class="w-20 w-sm-33">{{data.max}}</td>
                        </tr>
                    </table>
                </b-card-body>
            </b-collapse>
        </b-card>
    </div>
</template>

<script>
    export default {
        name: "AccordeonPrice",
        data : () => ({
            price_list: [
                { name:'Studio',  price:'$1,850', table: [
                        {members:1, min: '$63,429', max:'$128,570'},
                        {members:2, min: '$63,429', max:'$146,900'},
                    ]
                },
                { name:'One-Bedroom',  price:'$2,295', table: [
                        {members:1, min: '$78,686', max:'$128,570'},
                        {members:2, min: '$78,686', max:'$146,900'},
                        {members:3, min: '$78,686', max:'$165,230'},
                    ]
                },
                { name:'Two-Bedroom',  price:'$2,775', table: [
                        {members:2, min: '$95,143', max:'$146,900'},
                        {members:3, min: '$95,143', max:'$165,230'},
                        {members:4, min: '$95,143', max:'$183,560'},
                        {members:5, min: '$95,143', max:'$198,250'},
                    ]
                },
                { name:'Three-Bedroom',  price:'$3,200', table: [
                        {members:3, min: '$109,715', max:'$165,230'},
                        {members:4, min: '$109,715', max:'$183,560'},
                        {members:5, min: '$109,715', max:'$198,250'},
                        {members:6, min: '$109,715', max:'$227,630'},
                        {members:7, min: '$109,715', max:'$242,320'},
                    ]
                }


            ]
        })
    }
</script>

<style>
.card-header > div{
  outline: none;
}
</style>
<style  scoped>
.material-icons {
  font-size: 26px !important;
  line-height: 32px;
  color: #ffffff;
  vertical-align: top;
}

    .price-head{
        background: #5D98B0;
    }
    .price-body{
      background-color: #F1F4F7;
      border-radius: 0;
      border-color: transparent;
    }
    .card-header-custom {
      height: 60px;
      background: #2692F5;
      padding: 9px 30px 9px 10px!important;
    }
    .card-header-custom > div:focus {
      outline: none;
    }
    .price-room{
      font-family: Open Sans-ExtraBold;
      font-style: normal;
      font-weight: 800;
      font-size: 36px;
      line-height: 32px;
      color: #FFFFFF;
      margin-left: 16px;
    }
    .question {
      font-family: Open Sans-ExtraBold;
      font-style: normal;
      font-weight: 800;
      font-size: 26px;
      line-height: 32px;
      color: #FFFFFF;
    }
    .collapse-card-body {
      padding: 0 50px;
    }
    .collapse-card-body .t-head{
      height: 40px;
      font-family: Open Sans-Light;
      font-style: normal;
      font-weight: normal!important;
      font-size: 18px;
      line-height: 32px;
      color: #777777;
      text-transform: none;
    }
    .collapse-card-body .t-body{
      height: 48px;
      border-bottom: 1px solid #D8D8D8;
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 32px;
      color: #222222;
    }
    .collapse-card-body .t-body:last-of-type{
      border-bottom: 0px solid #D8D8D8;
    }
    .w-40 {
      width: 40%;
    }
    .w-20 {
      width: 20%;
    }
    .table-header-price {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 800;
      font-size: 25px;
      line-height: 32px;
      color: #FFFFFF;
    }
    #dot-close, #dot-open{
        display: none;
      margin-right: 10px;
    }
    .collapsed > table >tr > td #dot-open{
        display: inline-block;
    }
    .not-collapsed > table >tr > td #dot-close{
        display: inline-block;
    }

    #dot-close img,
    #dot-open img{
      width: 26px !important;
    }
  @media  (max-width:1025px) and (min-width:769px)  {
    .collapse-card-body .t-head{
      font-size: 16px;
      line-height: 32px;
    }
    .collapse-card-body .t-body{
      font-size: 16px;
      line-height: 32px;
    }
    .question {
      font-family: Open Sans-ExtraBold;
      font-size: 22px;
      line-height: 32px;
    }
    .price-room{
      font-size: 24px;
      line-height: 32px;
    }
    .table-header-price {
      font-size: 18px;
      line-height: 32px;
    }
  }

    @media  (max-width:769px) and (min-width:401px)  {
      .card-header-custom {
        height: 50px;
        padding: 4px !important;

      }
      #dot-close #dot-open{
        margin-right: 5px;
      }
      .price-room{
        font-size: 30px;
        line-height: 32px;
      }
      .question {
        font-size: 24px;
      }
      .table-header-price {
        font-size: 24px;
      }
      .table-header-price span{
        font-size: 18px;
      }
      .collapse-card-body {
        padding: 0 7px;
      }
      .collapse-card-body .t-head{
        font-size: 16px;
      }
    }
    @media  (max-width:500px) and (min-width:320px)  {
      .material-icons {
        font-size: 22px !important;
      }
      .card-header-custom {
        height: 50px;
        padding: 4px !important;
      }
      .price-room{
        font-size: 15px;
        line-height: 32px;
        margin-left: 10px;
      }
      #dot-close, #dot-open{
        margin-right: 5px;
      }
      .question {
        font-size: 14px;
      }
      .table-header-price {
        font-size: 15px;
      }
      .table-header-price span{
        font-size: 14px;
      }
      .collapse-card-body {
        padding: 0 7px;
      }
      .collapse-card-body .t-head{
        height: 30px;
        font-size: 11px;
        line-height: 16px;
      }
      .collapse-card-body .t-body{
        font-size: 13px;
      }
      .w-sm-33 {
        width: 33%;
      }
      #dot-close img,
      #dot-open img{
        width: 20px !important;
      }
    }
</style>
