<template>
<div class="apartments-wrapper position-relative" id="_apartments">
  <Carousel></Carousel>
  <div class="container-width">
    <div class="contaner-fluid">
      <div class="row">
        <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12">
          <p class="title">Apartments<span class="text-blue">.</span></p>
        </div>
        <div class="col-xl-7 col-lg-7 col-md-12 col-sm-12 text-left">
          <h2 class="header">Every room with a view. Each one, yours to create.</h2>
          <p class="content">At 5 Pointz your living room is your canvas. Neutral, elegant finishes are the perfect
            backdrop for you to create your home. <span class="content-bold">With 337 great apartments available through the lottery, you can enjoy significant
            savings while you realize your perfect space.</span> </p>
          <p class="content">Granite countertops and stainless steel appliances make your cooking space sing with
            modern convenience. Cook and create with inspiration and make the kitchen the heart of your home.</p>

        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Carousel from '@/components/Carousel';
export default {
  name: "Appartments",
  components: {
    Carousel
  }
}
</script>

<style scoped>
.apartments-wrapper {
  background: #F1F4F7;
  padding-top: 418px;
  padding-bottom: 441px;
}


.title {
  font-family: "Poppins-Bold";
  font-size: 80px;
  line-height: 90px;
  color: #000000;
}
.title .text-blue{
  color:#2692F5;
}
.header {
  font-family: "Poppins-ExtraBold";
  font-size: 24px;
  line-height: 36px;
  color: #111111;
  margin: 0 0 20px;
}
.content {
  font-family: "Poppins";
  font-size: 20px;
  line-height: 36px;
  color: #111111;
  margin-bottom: 20px;
}
.content .content-bold{
  font-family: "Poppins-Bold";
}
@media  (max-width:1520px)  {
  .title {
    font-size: 70px;
  }
}
@media  (max-width:1439px) and (min-width:1024px)  {
  .apartments-wrapper {
    padding-top: 330px;
    padding-bottom: 245px;
  }
  .title {
    font-size: 55px;
  }
  .header {
    font-size: 18px;
  }
  .content {
    font-size: 14px;
  }
}

@media  (max-width:1023px) and (min-width:768px)  {
  .apartments-wrapper {
    background: #fff;
    padding-top: 0px;
    padding-bottom: 60px;
  }
  .title {
    margin-top: 60px;
    font-size: 45px;
    line-height: 55px;
  }
  .header {
    font-size: 18px;
    line-height: 27px;
  }
  .content {
    font-size: 14px;
    line-height: 26px;
  }
}

@media  (max-width:767px) and (min-width:320px)  {
  .apartments-wrapper {
    padding-top: 0px;
    background: #fff;
    padding-bottom: 20px;

  }
  .title {
    margin-top: 30px;
    font-size: 32px;
    line-height: 38px;
  }
  .header {
    font-size: 16px;

    line-height: 24px;
  }
  .content {
    font-size: 12px;
    line-height: 22px;
  }
}
</style>
