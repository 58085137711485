<template>
 <div>
   <div class="container-width bg-color-white">

     <div class="affordable-rents-wrapper" id="_availability">
       <div class="d-flex justify-content-between">
         <div class="">
           <h2 class="affordable-rents-title">Rents<span class="text-blue">.</span></h2>
           <h6 class="affordable-rents-subtitle">Due to an extremely high volume of applicants it may take some time to process your application.
             You will be contacted according to your log number.
             Look for an email from <span class="text-blue">info@5Pointz-lottery.com</span> for more info.</h6>
         </div>
         <div class="">
<!--           <button class="affordable-rents-sign-me-up" v-b-modal.sign-up-modal>SIGN ME UP</button>-->
           <button class="affordable-rents-sign-me-up" @click="openSite()" > FAQs </button>
         </div>
       </div>
       <AccordeonPrice></AccordeonPrice>
     </div>
   </div>
 </div>
</template>

<script>
import AccordeonPrice from '@/components/AccordeonPrice'
export default {
name: "AffordableRents",
  components: {
    AccordeonPrice
  },
  methods: {
    openSite(){
      window.open("https://garnet-vein-480.notion.site/5Pointz-FAQs-e5021b8401414e4d87c3c0f4e5338f5f", "_blank");
    },
  }
}
</script>

<style scoped>
.affordable-rents-wrapper {
  padding-top: 200px;
  padding-bottom: 200px;
}
.affordable-rents-title {
  font-family: "Poppins-ExtraBold";
  font-size: 80px;
  line-height: 90px;
  color: #000000;
  margin-bottom: 12px;
}
.affordable-rents-title  .text-blue{
  color:#2692F5;
}
.affordable-rents-subtitle  .text-blue{
  color:#2692F5;
  font-family: "Poppins-ExtraBold";
}
.affordable-rents-subtitle {
  font-family: Poppins;
  font-size: 20px;
  line-height: 36px;
  color: #111111;
  margin-bottom: 49px;
  width: 860px;
}
.affordable-rents-sign-me-up {
  font-family: "Poppins-SemiBold";
  font-size: 23px;
  line-height: 60px;
  color: #FFFFFF;
  width: 329px;
  height: 92px;
  background: #000000;
  border-radius: 5px;
  border: 1px solid #000000;
  margin-top: 35px;
}

.affordable-rents-sign-me-up:hover {
  color:#000000;
  background: #ffffff;
  border: 1px solid #ffffff;
  transition: .35s;
}

@media  (max-width:1440px) and (min-width:1024px)  {
  .affordable-rents-title {
    font-size: 55px;
    line-height: 90px;
  }
  .affordable-rents-subtitle {
    width: 570px;
  }
  .affordable-rents-wrapper {
    padding-top: 80px;
    padding-bottom: 200px;
  }

}
@media  (max-width:1023px) and (min-width:768px)  {
  .affordable-rents-title {
    font-size: 55px;
    line-height: 90px;
  }
  .affordable-rents-wrapper {
    padding-top: 89px;
    padding-bottom: 80px;
  }
  .affordable-rents-subtitle {
    width: 100%;
  }
}
@media  (max-width:768px) and (min-width:500px)  {
 .affordable-rents-wrapper > div {
   display: block !important;
 }
  .affordable-rents-title {
    font-size: 32px;
    line-height: 38px;
    text-align: center;
  }
  .affordable-rents-subtitle {
    margin-bottom: 0;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    width: 100%;
  }
  .affordable-rents-sign-me-up {
    width: 100%;
    height: 60px;
    margin: 20px auto 20px;
  }
  .affordable-rents-wrapper {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media  (max-width:499px) and (min-width:320px)  {
 .affordable-rents-wrapper > div {
   display: block !important;
 }
  .affordable-rents-title {
    font-size: 32px;
    line-height: 38px;
    text-align: center;
  }
  .affordable-rents-subtitle {
    font-size: 12px;
    line-height: 22px;
    margin-bottom: 0;
    text-align: center;
    width: 100%;

  }
  .affordable-rents-sign-me-up {
    width: 100%;
    max-width: 583px;
    height: 60px;
    margin: 20px auto 20px;
  }
  .affordable-rents-wrapper {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}


</style>
