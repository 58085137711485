<template>
<div>
  <div class="container-width bg-color-white">
    <div class="container-fluid wrapper-lic" id="_long-island-city">
      <div class="row">
        <div id="first-img" class="col-lg-4 col-sm-6 col-xs-12 p-0">
          <div class="lic-img bg-first-img "></div>
        </div>
        <div class="col-lg-4 col-sm-6 col-xs-12 p-0 d-flex second-img">
          <div class="lic-img bg-second-img"></div>
        </div>
        <div class="col-lg-4 col-sm-6 p-0 third-img">
          <div class="lic-img bg-third-img"></div>
        </div>
        <div id="fourth-img" class="col-lg-4 offset-lg-1 col-sm-6 p-0  justify-content-end">
          <div class="lic-img bg-fourth-img"></div>
        </div>
        <div class="col-lg-7 col-sm-12 p-0 ">
          <div class="description">
            <p class="title">Long Island City<span class="text-blue">.</span></p>
            <p class="content">In the heart of Long Island City,
              across the street from MoMA PS1, and just one stop from both Manhattan and Brooklyn,
              5 Pointz has everything you want.
            </p>
            <p class="content">Whether Manhattan, Brooklyn, Queens or Long Island -
              if you need to get there, we’ve got you covered.
              You’ll arrive faster than you can say, “stand clear of the closing doors, please.”
            </p>
          </div>
        </div>
        <div id="last-one-img" class="col-lg-4 offset-lg-1 col-sm-6 p-0 justify-content-end">
          <div class="lic-img mt-20px bg-fourth-img"></div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
name: "LIC"
}
</script>

<style scoped>
.mt-20px{
  margin-top: 20px;
}
.wrapper-lic{
  position: relative;
  top: -230px;
  padding-bottom: 300px;
}

.bg-first-img{
  background: url("../assets/lic_poi_highres_2.jpg") no-repeat center;
  background-size: cover;
}
.bg-second-img{
  background: url("../assets/lic_poi_highres_34.jpg") no-repeat center;
  background-size: cover;
}
.bg-third-img{
  background: url("../assets/lic_poi_highres_8.jpg") no-repeat top;
  background-size: cover;
}
.bg-fourth-img{
  background: url("../assets/lic_poi_hires_22.jpg") no-repeat center;
  background-size: cover;
}

.title{
  font-family: "Poppins-Bold";
  font-size: 80px;
  line-height: 90px;
  color: #000000;
  margin : 83px 0 50px 0 ;
}
.text-blue{
  color: #2692F5;
}
.second-img{
  justify-content: center;
}
.third-img{
  display: flex;
  justify-content: flex-end
}
.content{
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 36px;
}
.content-bold{
  font-weight: 800;
  color: #000000;
}

.lic-img{
  width: 460px;
  height: 460px;
}
#fourth-img{
  display: none;
}
#last-one-img{
  display: flex;
}
@media  (max-width:1520px) and (min-width:1440px)  {
  .lic-img{
    width: 421px;
    height: 421px;
  }
  .wrapper-lic{
    /*height: 1060px;*/
  }
  .title{
    font-size: 70px;
    line-height: 90px;
    margin : 63px 0 50px 0 ;
  }
}
@media  (max-width:1439px) and (min-width:1024px)  {
  .lic-img{
    width: 310px;
    height: 310px;
  }
  .wrapper-lic{
    /*height: 1060px;*/
    top: -155px;
    padding-bottom: 200px;
  }
  .title{
    font-size: 55px;
    line-height: 90px;
    margin : 14px 0 44px 0 ;
  }
  .content{
    font-size: 14px;
    line-height: 26px;
  }
}

@media  (max-width:1023px) and (min-width:768px)  {
  .wrapper-lic{
    top: 0;
    position: static;
    padding-bottom: 0px;
  }
  .lic-img{
    width: 308px;
    height: 200px;
  }
  .wrapper-lic{
    /*height: 735px;*/
  }
  .title{
    font-size: 45px;
    line-height: 55px;
    margin : 60px 0 30px 0 ;
  }
  .content{
    font-size: 14px;
    line-height: 26px;
  }
  .second-img{
    justify-content: flex-end !important;
  }
  .third-img{
    justify-content: start;
    margin-top:17px;
  }
  #fourth-img{
    display: flex;
    margin-top:17px;
  }
  #last-one-img{
    display: none;
  }
}
@media  (max-width:767px) and (min-width:320px)  {
  .wrapper-lic{
    top: 0;
    position: static;
    padding-bottom: 0px;
  }
.lic-img{
width: 320px;
height: 168.42px;
}

.title{
font-size: 32px;
line-height: 38px;
margin : 30px 0 20px 0 ;
}
.content{
font-size: 12px;
line-height: 22px;
}
.second-img{
justify-content: center !important;
}
.third-img{
  display: none;

}
#first-img{
 display: flex;
  justify-content: center;
}
#last-one-img{
display: none;
}
}

</style>
