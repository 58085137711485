<template>
  <div class="carousel-wrapper">
    <b-carousel
        id="carousel"
        v-model="slide"
        :interval="4000"
        controls
        background="#ababab"
        style="text-shadow: 1px 1px 2px #333;">
      <b-carousel-slide v-for="(image, index) in images" :key="index" :img-src="`./carousel/${image}`"></b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>
export default {
  name: "Carousel",
  data: () => ({
    slide: 0,
    images: [
      '5_Pointz - Basketball Court.jpg',
      '5_Pointz - Garden 1.jpg',
      '5_Pointz - Garden 2.jpg',
      '5_Pointz - Party Deck.jpg',
      '5_Pointz - Party Deck Fall.jpg',
      '5_Pointz - Party Deck Games.jpg',

      '5PointzAmenities1.jpg',
      '5PointzAmenities2.jpg',
      '5PointzAmenities3.jpg',
      '5PointzAmenities4.jpg',
      '5PointzAmenities5.jpg',
      '5PointzAmenities6.jpg',
      '5PointzAmenities7.jpg',
      '5PointzAmenities8.jpg',
    ]
  })
}
</script>

<style>
.carousel-wrapper {
  position: absolute;
  z-index: 10;
  top: -350px;
  width: 1420px;
  height: 700px;
  left: calc(50% - 710px);
}

@media (min-width: 768px) {
  .carousel-control-prev,
  .carousel-control-next {
    position: absolute !important;
    width: 70px !important;
    height: 70px !important;
    right: 0px !important;
    bottom: 0px !important;
    top: auto !important;
    left: auto !important;
    opacity: 1 !important;
    background: #000000;
  }

  .carousel-control-prev {
    right: 72px !important;
  }
}
@media  (max-width:1520px)  {
  .carousel-wrapper {
    top: -350px;
    left: calc(50% - 650px);
    width: 1300px;
    height: 700px;
  }
  .carousel-item,
  .carousel-inner,
  .carousel,
  .carousel-item .img-fluid{
    height: 700px;
  }
}
@media  (max-width:1439px) and (min-width:1024px)  {
  .carousel-wrapper {
    top: -250px;
    left: calc(50% - 480px);
    width: 960px;
    height: 500px;
  }
  .carousel-item,
  .carousel-inner,
  .carousel,
  .carousel-item .img-fluid{
    height: 500px;
  }
}

@media  (max-width:1023px) and (min-width:760px)  {
  .carousel-wrapper {
    position: static;
    width: 100%;
    height: 500px;
  }
  .carousel-control-prev {
    right: auto !important;
    left: 0 !important;
  }
  .carousel-item,
  .carousel-inner,
  .carousel,
  .carousel-item .img-fluid{
    height: 500px;
  }
}

@media  (max-width:760px) and (min-width:320px)  {
  .carousel-wrapper {
    position: static;
    width: 100%;
    height: auto;
  }
  .carousel-item,
  .carousel-inner,
  .carousel,
  .carousel-item .img-fluid{
    height: auto;
  }
}
</style>