<template>
  <b-modal id="sign-up-modal"
           body-bg-variant="coool"
           header-bg-variant="coool"
           header-class="header-class"
           footer-class="p-0 m-0"
           content-class="modal-class"
           @hidden="resetModal()"
           centered
           size="lg"
           no-close-on-backdrop
           body-class="p-0">
    <template #modal-header>
    </template>
    <template #modal-footer>
      <div class=" w-100" >
        <div v-if="sent_success || sent_error" class="lic-less d-flex justify-content-center hand"
             @click="$bvModal.hide('sign-up-modal')">
          <p class="lic-text d-flex align-items-center m-0">
            CLOSE
          </p>
        </div>
        <div v-else class="lic-less d-flex justify-content-center"
             :class="disabled_send ? 'not-allowed' : 'hand'"
             @click="signUp">
          <p class="lic-text d-flex align-items-center m-0">
            SIGN ME UP
          </p>
        </div>
      </div>
    </template>
    <Preloader v-if="is_loading"></Preloader>
    <div v-if="sent_success" class="sent-pad">
      <img src="../assets/check_circle_outline.png" class="d-block mx-auto" alt="">
      <div class="d-block text-center">
        <p class="title">Success</p>
        <div class="descr ">
          <p class="mb-0">Thank you for signing up.  </p>
          <p class="mb-0">We will follow up as soon as possible regarding the next steps to complete your application</p>
        </div>
      </div>
    </div>
    <div v-else-if="sent_error" class="sent-pad">
      <img src="../assets/error_outline.png" class="d-block mx-auto" alt="">
      <div class="d-block text-center">
        <p class="title">Oops!</p>
        <div class="descr form-sent">
          <p class="mb-0">It looks like something went wrong. </p>
          <p class="mb-0">Please try again later.</p>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="d-block text-center">
        <p class="title">Sign up now<span class="text-blue">.</span></p>
        <p class="descr   ">To find out how to apply</p>
      </div>
      <div class="wrap-inputs">
        <div class="row radios-block">
          <div class="col-md-6 col-12 mb-25px">
            <i class="material-icons pic-pos" :class="name_state === false ? 'err-icon' : ''">account_circle</i>
            <div v-if="name_state === false" class="err-state text-center">
              <p v-if="!name || name.length === 0"  class="err-state-text m-0">
                Required
              </p>
              <p v-else class="err-state-text m-0">
                First name is incorrect
              </p>
            </div>
            <b-form-input
                id="name"
                class="cust-inp"
                v-model="name"
                :state="name_state"
                placeholder="First name"
                trim
                type="text"
            ></b-form-input>
          </div>
          <div class="col-md-6 col-12 mb-25px">
            <i class="material-icons pic-pos" :class="last_name_state === false ? 'err-icon' : ''">account_circle</i>
            <div v-if="last_name_state === false" class="err-state text-center">
              <p v-if="!last_name || last_name.length === 0"  class="err-state-text m-0">
                Required
              </p>
              <p v-else class="err-state-text m-0">
                Last name is incorrect
              </p>
            </div>
            <b-form-input
                id="lname"
                class="cust-inp"
                v-model="last_name"
                :state="last_name_state"
                placeholder="Last name"
                trim
                type="text"
            ></b-form-input>
          </div>
          <div class="col-md-6 col-12 mb-25px">
            <i class="material-icons pic-pos" :class="mail_state === false ? 'err-icon' : ''">email</i>
            <div v-if="mail_state  === false" class="err-state text-center">
              <p v-if="!mail || mail.length === 0"  class="err-state-text m-0">
                Required
              </p>
              <p v-else class="err-state-text m-0">
                Email is incorrect
              </p>
            </div>
            <b-form-input
                id="mail"
                class="cust-inp"
                v-model="mail"
                :state="mail_state"
                placeholder="Email"
                type="email"
                trim
            ></b-form-input>
          </div>
          <div class="col-md-6 col-12 mb-25px">
            <i class="material-icons pic-pos" :class="phone_state === false ? 'err-icon' : ''">call</i>
            <div v-if="phone_state  === false" class="err-state text-center">
              <p v-if="phone.length > 0 && !phone_state"  class="err-state-text m-0">
                Phone number is incorrect
              </p>
            </div>
            <TheMask  class="cust-inp form-control"
                      v-model="phone"
                      :class="phone_state ===  false ? 'is-invalid' : ''"
                      placeholder="(XXX) XXX-XXXX" m
                      mask="(###) ###-####">
            </TheMask>
        </div>
        </div>
        <div class="radio-wrap" :class="income_state === false ? 'radio-err' : ''">
          <div v-if="income_state === false" class="err-state long text-center">
            <p class="err-state-text  m-0">
              You need to indicate the level of your family's income
            </p>
          </div>
          <p class="descr text-center"> Your household income level:</p>
          <div class="row">
            <div class="col-md-4 col-sm-12" >
              <input type="radio" class="custom-radio" v-model="selected_income" id="under" name="radios" value="Under_70">
              <label for="under" :class="selected_income === 'Under_70' ? 'radios active' : 'radios'">Under $70K</label><br>
            </div>
            <div class="col-md-4 col-sm-12" >
              <input type="radio" class="custom-radio" v-model="selected_income" id="between" name="radios" value="70-100">
              <label for="between" :class="selected_income === '70-100' ? 'radios active' : 'radios'">$70K-$100K</label><br>
            </div>
            <div class="col-md-4 col-sm-12" >
              <input type="radio" class="custom-radio" v-model="selected_income" id="above" name="radios" value="Above_100">
              <label for="above" :class="selected_income === 'Above_100' ? 'radios active' : 'radios' ">Above $100K</label>
            </div>

          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {TheMask} from 'vue-the-mask'
import Preloader from '../components/Preloader.vue'
import axios from 'axios'
import sbjs from 'sourcebuster';
import Vue from "vue";

Vue.use(sbjs)
sbjs.init();
export default {
name: "SignUpModal",
  components: {
    TheMask,
    Preloader
  },

  data: () => ({
    name_state: null,
    last_name_state: null,
    mail_state: null,
    phone_state: null,
    income_state: null,
    name: null,
    last_name: null,
    mail: null,
    phone: null,
    selected_income: null,
    sent_success : false,
    sent_error : false,
    is_loading : false,
    disabled_send : false,
  }),
  watch:{
    name(){
      this.name_state = null
    },
    last_name(){
      this.last_name_state = null
    },
    mail(){
      this.mail_state = null
    },
    phone(){
      this.phone_state = null
    },
    selected_income(){
      this.income_state = null
    },
  },
  methods:{
    resetModal(){
      this.sent_success = false;
      this.is_loading = false;
      this.sent_error = false;
      this.name = null;
      this.last_name = null;
      this.mail = null;
      this.phone = null;
      this.selected_income = null;
      this.name_state = null;
      this.last_name_state =  null;
      this.mail_state = null;
      this.phone_state = null;
      this.income_state = null;

    },
    async signUp(){
      if(this.validate() && !this.disabled_send){
        this.makeDisabled()
        this.is_loading = true;
        await axios.post('https://mgny.force.com/sales/services/apexrest/lead/add',
            {
              'FirstName': this.name,
              'LastName': this.last_name,
              'Email': this.mail,
              'Phone': this.phone,
              'Income' : this.selected_income,
              'FirstSource' : sbjs.get.first.src + '-' +  sbjs.get.first.mdm + '-' + sbjs.get.first.cmp + '-' + sbjs.get.first.trm,
              'LastSource' : sbjs.get.current.src + '-' +  sbjs.get.current.mdm + '-' + sbjs.get.current.cmp + '-' + sbjs.get.current.trm,

            })
            .then(()=>{
              this.sent_success = true
              this.is_loading = false;
            }).catch((error)=>{
              this.sent_error = true;
              this.is_loading = false;
              console.log('Error' + error)
            })
      }
    },
    makeDisabled(){
      this.disabled_send = true;
      setTimeout(() => this.disabled_send = false, 10000)
    },
    validate(){
      this.name_state = null;
      this.last_name_state = null;
      this.mail_state = null;
      this.phone_state = null;
      this.income_state = null
      let is_valid = true
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if(this.name === null || this.name.length < 2){
        this.name_state = false;
        is_valid = false
      }
      if(this.last_name === null || this.last_name.length < 2){
        this.last_name_state = false;
        is_valid = false
      }
      if(this.mail === null || this.mail.length < 5 || !(re.test(String(this.mail).toLowerCase())) ){
        this.mail_state = false;
        is_valid = false
      }
      if(this.phone && this.phone.length !== 10){
        this.phone_state = false;
        is_valid = false
      }
      if(!this.selected_income){
        this.income_state = false;
        is_valid = false
      }

    return is_valid

    }
  }
}
</script>
<style>
.radio-err{
  border: 1px solid #FF2929;
  box-sizing: border-box;
  border-radius: 5px;
}
.wrap-inputs{
  padding: 0 97px;
  width: 100% !important;
  margin-top: 30px;
}
.radio-wrap{
  margin: 20px 0 50px 0;
  position: relative;
}
.radios{
  font-family: Poppins;
  font-style: normal;
  font-size: 18px;
  line-height: 32px;
  color: #AAAAAA;
  margin-left: 11px;
}
.radios.active {
  color: #000000;
}
.not-allowed{
  cursor: not-allowed;
}
.modal-header {
  border-bottom: none!important;
}
.bg-coool{
  background: #F1F4F7;
}
.modal-content {
  border-radius: 10px 10px 0px 0px!important;
}
.modal-class{
  border-radius: 10px!important;
}
.header-class{
  border-radius: 10px!important;
}
.modal-footer > * {
  margin: 0!important;
}
.was-validated .form-control:invalid, .form-control.is-invalid {
  color: #FF2929;
}
.form-control {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
}
</style>
<style scoped>


.sent-pad{
  padding: 56px 46px 112px;
}
.err-state-text{
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #FFFFFF;
}
.err-state{
  width: 170px;
  height: 18px;
  background: #FF2929;
  border-radius: 2px;
  position: absolute;
  top: -22px;
  right: 17px;
}
.err-state.long{
  width: 350px;
  right: 0px;
}
.err-state::after{
  content: '';
  position: absolute;
  left: 49%;
  top: 100%;
  border-left: 4px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #f60606;
}
::placeholder {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;

  color: #C6C6C6;
}
.mb-25px{
  margin-bottom: 25px;
}
.mb-40px{
  margin-bottom: 40px;
}
.title{
  font-family: "Poppins-Bold";
  font-size: 45px;
  line-height: 60px;
  color: #000000;
  margin: 36px 0 5px;
}
.descr{
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 36px;
}
.text-blue{
  color: #2692F5;
}
.description-wrap{
  padding:30px
}
.cust-inp{
  padding-left: 36px;
}
.pic-pos{
  position: absolute;
  top: -5px;
  left: 22px;
  font-size: 26px!important;
  line-height: 48px!important;
  color: #C6C6C6;
}

.pic-has-err{

}
.hand{
  cursor: pointer;
}
.price-wrap{
  background: #EAF6FB;
  min-height: calc(100vh - 76px);
  overflow: auto;
}
.lic-text{
  font-family: Integral CF ExtraBold;
  font-style: normal;
  font-weight: 800;
  font-size: 31px;
  line-height: 37px;
  text-align: center;
  letter-spacing: 0.05em;

  color: #FFFFFF;

}
.lic-less{
  /* margin-top: 45px; */
  height: 100px;
  width: 100%;
  background: #2692F5;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
}
.err-icon{
  color: #FF2929;
}
.custom-radio {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

/* для элемента label связанного с .custom-radio */
.custom-radio+label {
  display: inline-flex;
  align-items: center;
  user-select: none;
}

/* создание в label псевдоэлемента  before со следующими стилями */
.custom-radio+label::before {
  content: '';
  display: inline-block;
  width: 1em;
  height: 1em;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #adb5bd;
  border-radius: 50%;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

/* стили при наведении курсора на радио */
.custom-radio:not(:disabled):not(:checked)+label:hover::before {
  border-color: #b3d7ff;
}

/* стили для активной радиокнопки (при нажатии на неё) */
.custom-radio:not(:disabled):active+label::before {
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}

/* стили для радиокнопки, находящейся в фокусе */
.custom-radio:focus+label::before {
  box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25);
}

/* стили для радиокнопки, находящейся в фокусе и не находящейся в состоянии checked */
.custom-radio:focus:not(:checked)+label::before {
  border-color: #80bdff;
}

/* стили для радиокнопки, находящейся в состоянии checked */
.custom-radio:checked+label::before {
  width: 20px;
  height: 20px;
  border: 2px solid #2692F5;
  box-sizing: border-box;
  border-radius: 10px;
  background-image:  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4' fill='%232692F5'/%3e%3c/svg%3e");
}
@media  (max-width:1023px) and (min-width:768px)  {
  .sent-pad{
    padding: 28px 46px 56px;
  }
}
@media  (max-width:767px)  {
  .wrap-inputs {
    padding: 0 10px;
    margin-top: 0
  }
  .sent-pad{
    padding: 18px 46px 26px;
  }
  .title {
    margin: -20px 0 5px;
  }
  .radio-wrap {
    margin: 0px 0 5px 0;
    position: relative;
  }
  .mb-25px {
    margin-bottom: 20px;
  }
  .descr{
    margin-bottom: 10px;
  }

}

</style>